import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/Seo";

import { Terms } from "../components/sections/Terms";
import { Footer } from "../components/sections/Footer";

const TermsPage = () => {
  return (
    <Layout>
      <SEO title="Terms and Conditions - Boost your ROAS" />

      {/* <Navigation /> */}
      <Terms />
      <Footer />
    </Layout>
  );
};

export default TermsPage;
